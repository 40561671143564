import React from 'react'
import { Link } from 'gatsby' 
import Layout from '../layouts/simple.js'
import '../styles/brands.scss'
import Brand from '../images/brand.jpg'
import CTA from '../components/cta.js'

const Brands = () => {
    return (
        <Layout>
            <section className="hero">
                <div className="hero-background">
                    <div className="container">
                        <div className="hero-content">
                            <h1>We Work With The World's Top Brands</h1>
                            <h3>For 100 years we've made problem-solving designs that are as beautiful as they are useful.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section id="overview" className="py-60">
                <div className="container">
                    <div className="column-two">
                        <div>
                            <img src={Brand}/>
                            <h4>Herman Miller</h4>
                            <p>Specializes in the design and manufacturing of modular wood casegoods and architectural furniture for private offices and commercial interiors. It is headquartered in Atlanta, Georgia.</p>
                        </div>
                        <div>
                            <img src={Brand}/>
                            <h4>Herman Miller</h4>
                            <p>The largest retailer of authentic modern furniture and accessories in the world. Headquartered in Stamford, Connecticut, DWR operates retail Studio locations across North America, an e-commerce website, and a popular print catalog.</p>
                        </div>
                        <div>
                            <img src={Brand}/>
                            <h4>Herman Miller</h4>
                            <p>Specializes in the design and manufacturing of modular wood casegoods and architectural furniture for private offices and commercial interiors. It is headquartered in Atlanta, Georgia.</p>
                        </div>
                        <div>
                            <img src={Brand}/>
                            <h4>Herman Miller</h4>
                            <p>Specializes in the design and manufacturing of modular wood casegoods and architectural furniture for private offices and commercial interiors. It is headquartered in Atlanta, Georgia.</p>
                        </div>
                        <div>
                            <img src={Brand}/>
                            <h4>Herman Miller</h4>
                            <p>Specializes in the design and manufacturing of modular wood casegoods and architectural furniture for private offices and commercial interiors. It is headquartered in Atlanta, Georgia.</p>
                        </div>
                        <div>
                            <img src={Brand}/>
                            <h4>Herman Miller</h4>
                            <p>Specializes in the design and manufacturing of modular wood casegoods and architectural furniture for private offices and commercial interiors. It is headquartered in Atlanta, Georgia.</p>
                        </div>
                    </div>
                </div>
            </section>
            <CTA />
        </Layout>
    )
    }

    export default Brands